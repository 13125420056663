// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-articles-managing-data-science-projects-js": () => import("./../../../src/pages/articles/managing-data-science-projects.js" /* webpackChunkName: "component---src-pages-articles-managing-data-science-projects-js" */),
  "component---src-pages-articles-understanding-and-managing-uncertainty-in-data-science-projects-js": () => import("./../../../src/pages/articles/understanding-and-managing-uncertainty-in-data-science-projects.js" /* webpackChunkName: "component---src-pages-articles-understanding-and-managing-uncertainty-in-data-science-projects-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-style-js": () => import("./../../../src/pages/style.js" /* webpackChunkName: "component---src-pages-style-js" */)
}

